import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
// import logo from "../Assets/Images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import YoutubeVideo from "./YoutubeVideo";
// import ProductComparision from "./ShoppingCart/ProductComparision";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: "12px 16px", color: "rgb(109 79 160)" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#000",
            top: "-10px",
            background: "#fff",
            "&:hover": {
              background: "#fff",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function VideoPop({
  subscribeModal,
  setSubscribeModal,
  videoLink,
}) {
  const [modalHeight, setModalHeight] = React.useState(window.innerHeight);
  const [showBox, setShowBox] = React.useState(false); // Track if the box should be shown
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    const handleResize = () => {
      setModalHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

   
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const closeHandle = () => {
    setShowBox(false)
    setSubscribeModal(false);
  };
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current?.currentTime || 0;
    if (currentTime >= 59 && !showBox) {
      setShowBox(true);
    }
    if (currentTime < 58 && showBox) {
      setShowBox(false);
    }
  };

  React.useEffect(() => {
    if(videoRef.current){
      videoRef.current?.play();
    }
  },[videoRef.current])
  
  const playVideoAgain = () => {
    videoRef.current?.play();
    setShowBox(false)
  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => closeHandle()}
        aria-labelledby="customized-dialog-title"
        open={subscribeModal}
        sx={{
          "& .MuiPaper-root": {
            overflowY: "inherit",
          },
          "& .MuiDialog-paper": {
            width: "1222px",
            maxWidth: "1222px",
            background: "#E4D5F4",
            // border: "5px solid #fff",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => closeHandle()}
        >
    
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              background: "#F7F7F9",
              //   padding: "1.5rem",
              borderRadius: "12PX",
              "& label": {
                mb: "0.3rem",
                display: "block",
                color: "#000",
                fontSize: "18px",
                fontFamily: "ABLE !important",
              },
              "& input": {
                background: "#F7F7F9",
                p: "10px",
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
                border: "0",
                color: "#000",
                mb: "0.5rem",
                "&:focus": {
                  outline: "none",
                },
                "&::placeholder": {
                  color: "#E4D5F4",
                },
              },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "ABLE",
                fontSize: "2rem",
                color: "rgb(109 79 160)",
                // fontWeight:600,
                textAlign:"center",
                p: "1rem",
              }}
            >
              <b>Webinar</b> - <a target="_blank"
                href="https://e-campus-registration.bp.edu/special/optimizing-respiration-for-achieving-high-performance?showbrochure=1">Optimizing Respiration for Achieving High
              Performance</a> <br />
              <small>A 15-hour live webinar on April 5 and 6, 08:30 - 16:30 Mountain Time</small>
            </Typography>
            <Box
              sx={{
                background: "#F7F7F9",
                position:"relative",
                borderRadius: "12px",
                // p: { sm: "1rem", xs: "1rem" },
                // my: "1.5rem",
                "& iframe": {
                  "@media (max-width:600px)": {
                    height: "200px",
                  },
                },
              }}
            >
             {showBox && <Box
                  sx={{
                    position: "absolute",
                    
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "rgb(0 0 0 / 58%)", 
                    borderRadius: "12px",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    zIndex: 2,
                    backdropFilter:"blur(2px)"
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "ABLE",
                      fontSize: "1.2rem",
                      color: "#000",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    <a
                      style={{
                        background: "rgb(109 79 160)",
                        color: "#fff",
                        fontFamily: "ABLE",
                        padding: "6px 8px",
                        border: "2px solid #C5A7CF",
                        borderRadius: "6px",
                        textDecoration: "none",
                      }}
                      className="btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://e-campus-registration.bp.edu/special/optimizing-respiration-for-achieving-high-performance?showbrochure=1"
                    >
                      Click here for Brochure & Registration
                    </a>
                    
                  </Typography>
                </Box>}
                <video
                ref={videoRef}
                width="100%"
                height={modalHeight - 300}
                style={{ borderRadius: "10px" }}
                src={videoLink}
                controls
                onTimeUpdate={handleTimeUpdate}
              ></video>
              {/* <YoutubeVideo modalHeight={modalHeight}/> */}
            </Box>
            <Typography
              sx={{
                fontFamily: "ABLE",
                fontSize: "1.2rem",
                color: "#000",
                fontWeight: 600,
                p: "1rem",
                textAlign:"center"
              }}
            >
         
              <a
                style={{
                  background: "rgb(109 79 160)",
                  color: "#fff",
                  fontFamily: "ABLE",
                  padding: "6px 8px",
                  border: "2px solid #C5A7CF",
                  borderRadius: "6px",
                  textDecoration: "none",
                }}
                className="btn"
                target="_blank"
                href="https://e-campus-registration.bp.edu/special/optimizing-respiration-for-achieving-high-performance?showbrochure=1"
              >
                {" "}
                Click here for Brochure & Registration
              </a>
            </Typography>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
