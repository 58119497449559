import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import quickstart from "../../Assets/Images/quickstart.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "../../Components/Navbar"
import { Link } from "react-router-dom";

const GoQuickStart = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  const goManualData=[
    {
      id:1,
      title:"View/Download GO Quickstart",
      link:"https://betterphysiology-media.s3.us-east-1.amazonaws.com/Quick+Start+GO+fold+version.pdf"
      // link:"https://better-physiology-videos.s3.amazonaws.com/Quick-Start-GO-fold-version-July-2023.pdf"
    },
    {
      id:2,
      title:"View/Download GO Services",
      link:"https://betterphysiology-media.s3.us-east-1.amazonaws.com/GO+Support.pdf"
      // link:"https://better-physiology-videos.s3.amazonaws.com/GO-Support.pdf"
    },

  ]
  return (
    <Container>
    <Navbar/>
      <Box sx={{ color: "#000", mt: "2rem"  }} data-aos="fade-up" data-aos-duration="500">
        <Title title={`GO Quickstart`}   />
        <Typography pb={"1.5rem"} style={{fontStyle:"italic", fontSize: "14px" , color: "#000"}}>Click on an option below to download a PDF copy.</Typography>
      {
        goManualData.map((e,index)=>(
          <Box key={index} pb="1rem">
       <Typography
          component={"a"}
          href={e.link}
          sx={{
            fontSize: { sm: "22px", xs: "17px" },
            fontFamily: "ABLE",
            lineHeight: { sm: "32px", xs: "30px" },
            color:"#000",
            textDecoration: "none",
            fontStyle: "italic"
          }}
          target="_blank"
        >
         {e.title}
        </Typography>
       </Box>
        ))
      }
      <Box sx={{background:"#F7F7F9",borderRadius:"8px",textAlign:"center",padding:"5rem 2rem",my:"2rem"}}>
          {" "}
         <a href="https://better-physiology-videos.s3.amazonaws.com/Quick-Start-GO-fold-version-July-2023.pdf" target="_blank"> <Typography component={"img"} src={quickstart} sx={{width:{sm:"35%",xs:"50%"}}}/></a>
        </Box>
      </Box>
      <Box pt={"5rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default GoQuickStart;
