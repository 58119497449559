import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import CartBox from "./CartBox";
import HistoryTypography from "../History/HistoryTypography";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import new_image from "../../Assets/Images/new_3_.png";
import Navbar from "../../Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import { getProducts } from "../../store/ProductSlice";
import { getCart } from "../../store/cartSlice";
import SuccesAlert from "../../Components/SuccesAlert";
import axios from "axios";
import { API_URL } from "../../redux/Constant";
import GoInstruments from "./GoInstruments";
import Subscribe from "../Subscribe";
import CartBox1 from "./CartBox1";

const ShoppingCart = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loadingKit, setLoadingKit] = useState(false);
  const [category_1, setCategory_1] = useState(false);
  const [category_3, setCategory_3] = useState(false);
  const [category_2, setCategory_2] = useState(false);
  const [addSuccessMsg, setAddSuccessMsg] = useState("");
  const [addErrorMsg, setAddErrorMsg] = useState("");

  const [subscribeModal, setSubscribeModal] = useState(false);

  const dispatch = useDispatch(); 
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);
  const { data: result } = useSelector((state) => state.products);

  const getProductByCategory_1 = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/specified/product/by/9`);
      if (res.status === 200) {
        setCategory_1(res.data.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const getProductByCategory_3 = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/specified/product/by/20`);
      if (res.status === 200) {
        setCategory_3(res.data.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getProductByCategory_2 = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/specified/product/by/8`);
      if (res.status === 200) {
        setCategory_2(res.data.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getProductByCategory_1();
    getProductByCategory_2();
    getProductByCategory_3();
  }, []);

  const handlecomparison = () => {
    // alert("function call")
    setSubscribeModal(true);
  };
  const addToCartFunc = async (productId, quantity) => {
    setLoadingKit(true);
    try {
      const res = await axios.post(`${API_URL}/add-to-cart`, {
        user_id: localStorage.getItem("user")
          ? localStorage.getItem("user")
          : "",
        product_ids: [productId],
        quantities: [quantity],
      });
      if (res.status === 200) {
        setLoadingKit(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddSuccessMsg("");
        }, 2000);
        setAddSuccessMsg(res.data.message);
        const userId = res.data.user_id ;
        localStorage.setItem("user", res.data.user_id);
        dispatch(getCart(userId));
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoadingKit(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddErrorMsg("");
        }, 2000);
        setAddErrorMsg("Out of stock");
      }
    }
  };
  return (
    <Container>
      <SuccesAlert
        showSnackbar={showSnackbar}
        addSuccessMsg={addSuccessMsg}
        addErrorMsg={addErrorMsg}
      />
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`Shopping Cart`} paddingb={"1.5rem"} />
        <Box sx={{ pb: "2rem", pt: { sm: "0.3rem" } }}>
          <NewTypography
            text={"CapnoTrainer GO Instruments"}
            fsm={"30px"}
            fxs={"28px"}
            lsm={"30px"}
            lxs={"30px"}
            textalign={"center"}
            fontWeight={600}
            subtitle={
              "Click on the product name for details. Be sure to order a Starter Kit with your CapnoTrainer."
            }
          />
        </Box>

        <Box>
          {result?.data ? (
            <Grid container spacing={3} mb={"3rem"}>
              <Grid item md={3.5} sm={12} xs={12}>
                <Box
                  sx={{
                    borderRadius: "6px",
                    border: "1px solid #dad5d5",
                    lineHeight: "0",
                  }}
                >
                  <Box
                    sx={{
                      margin: "0 auto",
                      width: { sm: "100%", xs: "100%" },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={new_image}
                      sx={{
                        width: "100%",
                        p:"1rem"
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8.5} sm={12} xs={12}>
                {result.data?.map((data, index) => (
                  <GoInstruments
                    data={data}
                    setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                    setAddErrorMsg={setAddErrorMsg}
                  />
                ))}
                <Box>
                  {/* <HistoryTypography
                    text1={`NOTE: `}
                    text2={`Be sure to order a Starter Kit with your CapnoTrainer.`}
                    fsm={"20px"}
                    fxs={"20px"}
                    lsm={"30px"}
                    lxs={"25px"}
                    fsm1={"18px"}
                    fxs1={"18px"}
                    lsm1={"20.76px"}
                    lxs1={"20px"}
                  /> */}
                  <Typography
                    sx={{
                      py: "0.3rem",
                      fontSize: { sm: "18px", xs: "18px" },
                      fontFamily: "ABLE",
                      lineHeight: { sm: "23px", xs: "23px" },
                      textAlign: { sm: "start", xs: "start" },
                      fontWeight: "600",
                      fontStyle:"italic"
                    }}
                  >
                    <a
                      style={{
                        textDecoration: !loadingKit && "underline",
                        color: "rgb(109 79 160)",
                        fontSize: "18px",
                        fontWeight: "600",
                      fontStyle:"italic",
                        cursor: "pointer",
                      }}
                      onClick={() => addToCartFunc(50, 1)}
                      // href="#supply"
                      title="Order a Starter Kit"
                    >
                      {loadingKit ? "Processing..." : "Click here"}
                    </a>{" "}
                    to order a Starter Kit with your CapnoTrainer.
                  </Typography>
                  <Typography
                    sx={{
                      py: "0.5rem",
                      fontSize: { sm: "18px", xs: "18px" },
                      fontFamily: "ABLE",
                      lineHeight: { sm: "23px", xs: "23px" },
                      textAlign: { sm: "start", xs: "start" },
                      fontStyle:"italic",
                      fontWeight: "600",
                    }}
                  >
                    <a
                      style={{
                        textDecoration: "underline",
                        color: "rgb(109 79 160)",
                        fontSize: "18px",
                        fontWeight: "600",
                        cursor: "pointer",
                        fontStyle:"italic"
                      }}
                      onClick={handlecomparison}
                    >
                      Click here
                    </a>{" "}
                    to see which GO might be best for you.
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { sm: "18px", xs: "18px" },
                      fontFamily: "ABLE",
                      lineHeight: { sm: "32px", xs: "28px" },
                      textAlign: { sm: "start", xs: "justify" },
                      color: "rgb(109 79 160)",
                      fontWeight: "600",
                      fontStyle:"italic"
                    }}
                  >
                    NOTE:{" "}
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: { sm: "18px", xs: "18px" },
                        fontFamily: "ABLE",
                        textAlign: { sm: "start", xs: "justify" },
                        color: "#040404",
                        fontWeight: "600",
                      }}
                    > Actual size 9.7 X 7.6 X 3.2 cm (3.8” X 3.0” X 1.25”)
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              {/* {result.data?.map((data, index) => (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                  <CartBox
                    data={data}
                    setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                    setAddErrorMsg={setAddErrorMsg}
                  />
                </Grid>
              ))} */}
            </Grid>
          ) : (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          )}
          <Box
            sx={{
              borderColor: "rgb(109 79 160)",
              borderWidth: "1px 0 1px",
              py: "1.5rem",
              borderStyle: "solid",
            }}
          >
            <HistoryTypography
              text1={`NOTE: `}
              text2={`You may upgrade your GO Basic or GO Personal at any time by logging into your CapnoLearning Cloud Database account and clicking on SUBSCRIPTION. This includes Option C, Option M, GO Basic (both C and M) and GO Professional.`}
              fsm={"26px"}
              fxs={"21px"}
              lsm={"30px"}
              lxs={"25px"}
              fsm1={"22px"}
              fxs1={"18px"}
              lsm1={"20.76px"}
              lxs1={"20px"}
            />
          </Box>
          <Box sx={{ pb: "2rem", pt: { xs: "2rem" } }} id="supply">
            <NewTypography
              text={"Supplies for CapnoTrainer GO"}
              fsm={"28px"}
              fxs={"28px"}
              lsm={"30px"}
              lxs={"30px"}
              textalign={"center"}
              fontWeight={600}
            />
          </Box>
          {category_2 ? (
            <Grid container spacing={3} mb={"3rem"}>
              {category_2?.map((data, index) =>
                
                  <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                    <CartBox1
                      data={data}
                      setShowSnackbar={setShowSnackbar}
                      setAddSuccessMsg={setAddSuccessMsg}
                      setAddErrorMsg={setAddErrorMsg}
                    />
                  </Grid>
                
              )}
            </Grid>
          ) : (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          )}
          <Box sx={{ pb: "2rem", pt: { sm: "2rem" } }}>
            <NewTypography
              text={"Supplies for CapnoTrainer P6.0"}
              fsm={"28px"}
              fxs={"28px"}
              lsm={"30px"}
              lxs={"30px"}
              textalign={"center"}
              fontWeight={600}
            />
          </Box>
          {category_1 ? (
            <Grid container spacing={3} mb={"1rem"}>
              {category_1?.map((data, index) => (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                  <CartBox1
                    data={data}
                    setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                    setAddErrorMsg={setAddErrorMsg}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          )}

          <Box sx={{ pb: "2rem", pt: { sm: "2rem" } }}>
            <NewTypography
              text={"Supplies for CapnoTrainer 5.0 "}
              fsm={"28px"}
              fxs={"28px"}
              lsm={"30px"}
              lxs={"30px"}
              textalign={"center"}
              fontWeight={600}
            />
          </Box>
          {category_3 ? (
            <Grid container spacing={3} mb={"1rem"}>
              {category_3?.map((data, index) => (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                  <CartBox1
                    data={data}
                    setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                    setAddErrorMsg={setAddErrorMsg}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          )}

          <Box pt={"5rem"}>
            <Copyright />
          </Box>
        </Box>
      </Box>
      <Subscribe
        subscribeModal={subscribeModal}
        setSubscribeModal={setSubscribeModal}
      />
    </Container>
  );
};

export default ShoppingCart;
