import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const YoutubeVideo = ({ modalHeight }) => {
  const [showBox, setShowBox] = useState(false); // Track if the box should be shown
  const videoRef = useRef(null);

  useEffect(() => {
    let player;

    // Load YouTube Player API
    const onYouTubeIframeAPIReady = () => {
      player = new window.YT.Player(videoRef.current, {
        events: {
          onStateChange: onPlayerStateChange,
        },
      });
    };

    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.PLAYING) {
        const interval = setInterval(() => {
          const currentTime = player.getCurrentTime();
          if (Math.floor(currentTime) >= 59) {
            setShowBox(true);
            clearInterval(interval);
          }
        }, 1000);
      }
    };

    // Dynamically load YouTube IFrame API script
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      tag.onload = onYouTubeIframeAPIReady;
      document.body.appendChild(tag);
    } else {
      onYouTubeIframeAPIReady();
    }
  }, []);

  return (
    <div>
      {showBox && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "rgb(0 0 0 / 58%)",
            border: "2px solid #C5A7CF",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backdropFilter: "blur(2px)",
          }}
        >
          <Typography
            sx={{
              fontFamily: "ABLE",
              fontSize: "1.2rem",
              color: "#000",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            <a
              style={{
                background: "rgb(109 79 160)",
                color: "#fff",
                fontFamily: "ABLE",
                padding: "6px 8px",
                border: "2px solid #C5A7CF",
                borderRadius: "6px",
                textDecoration: "none",
              }}
              className="btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://e-campus-registration.bp.edu/special/optimizing-respiration-for-achieving-high-performance?showbrochure=1"
            >
              Click here for Brochure & Registration
            </a>
          </Typography>
        </Box>
      )}
      <iframe
        ref={videoRef}
        width="100%"
        height={modalHeight - 300}
        style={{ borderRadius: "10px" }}
        src="https://www.youtube.com/embed/ukPMcEH7iD0?enablejsapi=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YoutubeVideo;
