import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import graph from "../Assets/Images/graph_new.png";
import OnSaleFlash from "../Pages/OnSale";


const Title = ({ title,position, paddingb }) => {
  return (
    <>
      <Grid container spacing={2} sx={{pb:{lg:"0"},}}>
        <Grid item md={12} sm={12} xs={12} sx={{paddingBottom:paddingb}}>
        {/* <Grid item md={6} sm={6} xs={12} sx={{paddingBottom:'5rem'}}> */}
          <Typography
            variant="h2"
            sx={{
              fontFamily: "ABLE",
              // fontSize: {
              //   lg: "4rem",
              //   md: "3.5rem",
              //   sm: "3rem",
              //   xs: "2.5rem",
              // },
              fontSize: "2.3rem" ,
              position:{sm:position},
              // lineHeight:{lg:"100px"},
              color: "rgb(109 79 160)",
              fontWeight:600,            
              lineHeight: "2.8rem",
              textAlign: { md: "inherit", sm: "inherit", xs: "center" },
            }}
          >
            {title}

          </Typography>
          <OnSaleFlash />
          
        </Grid>
        {/* <Grid item md={6} sm={6} xs={12}>
          <Box position={"relative"} pb="1rem"> */}
          {/* <Typography component={"img"} src={graph} width={"100%"} sx={{height:{sm:"95px",xs:"auto"}}} /> */}
          {/* <Box sx={{position:"absolute",background:"linear-gradient(to right, rgb(255 255 255 / 100%), rgb(255 255 255 / 80%),rgba(255, 255, 255, 0))",height:"100%",width:"100%",top:"0"}}>

          </Box>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default Title;
