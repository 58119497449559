import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import VideoPop from "./GoVideos/VideoPop";

const OnSaleFlash = () => {
    const [visible, setVisible] = useState(true);
    const [videoModal, setVideoModal] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisible((prev) => !prev);
        }, 500);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Typography onClick={() => setVideoModal(true)} style={{
                background: `${visible ? "purple" : "white"}`, color: `${visible ? "white" : "purple"}`, width: "auto", maxWidth: "360px", cursor: "pointer", textAlign: "center", position: "absolute", padding: '10px', right: 0, top: 0, zIndex: 1,
                alignContent: "center"
            }} >
                EXCITING WEBINAR
            </Typography>
            <VideoPop
                subscribeModal={videoModal}
                setSubscribeModal={setVideoModal}
                videoLink={"https://betterphysiology-media.s3-accelerate.amazonaws.com/PSBHS-Sonnon-04-2025-80.34.mp4.mp4"}
            />
        </>


    );
};

export default OnSaleFlash;